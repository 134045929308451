.btnGooglePlay {
  width: 150px;
  padding: 0px;
  border: none;
  background: none;
}

.btnGooglePlay:focus,
.btnGooglePlay:active {
  border: none;
  outline: none;
  outline-offset: none;
}

.container-cellphone {
  margin-right: 65px;
  transform: rotate(-20deg);
}

@media (min-width: 350px) {
  .btnGooglePlay {
    width: 180px;
  }
}

@media (min-width: 576px) {
  .btnGooglePlay {
    width: 150px;
  }
}
