.card-mvv .container-card {
  height: auto;
}

.card-mvv .container-card p {
  font-size: 1rem;
}

.card-mvv .container-card-img {
  height: 120px;
}

@media (min-width: 768px) {
  .card-mvv .container-card {
    height: 350px;
  }

  .card-mvv .container-card p {
    font-size: 0.8rem;
  }
}

@media (min-width: 900px) {
  .card-mvv .container-card {
    height: 375px;
  }

  .card-mvv .container-card p {
    font-size: 1rem;
  }

  .card-mvv .container-card-img {
    height: 140px;
  }
}

@media (min-width: 1200px) {
  .card-mvv .container-card {
    height: 450px;
  }

  .card-mvv .container-card p {
    font-size: 1.25rem;
  }

  .card-mvv .container-card-img {
    height: 180px;
  }
}
