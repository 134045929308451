.page-intro {
  position: relative;
  color: #fff;
}

#imgWavesIntro {
  position: absolute;
  bottom: -1px;
}

.container-buttons hr {
  border-color: #fff;
  margin-left: 1rem;
  margin-right: 1rem;
}

.intro-link {
  color: #fff;
  font-size: 1.4em;
  line-height: 1.6rem;
  font-weight: 600;
  transition: 300ms ease;
  white-space: nowrap;
}

.intro-link:hover {
  color: #fff;
  font-size: 1.6rem;
  outline: none;
  text-decoration: none;
}

.intro-link {
  font-size: 1rem;
}

.intro-link:hover {
  font-size: 1.2rem;
}

@media (min-width: 630px) {
  .intro-link {
    font-size: 1.2rem;
  }

  .intro-link:hover {
    font-size: 1.5rem;
  }
}

@media (min-width: 900px) {
  .intro-link {
    font-size: 1.4rem;
  }

  .intro-link:hover {
    font-size: 1.6rem;
  }
}
