@font-face {
  font-family: spaceGrotesk;
  src: url(../../assets/fonts/space-grotesk-regular.otf);
  font-weight: bold;
}

.container-footer p,
.container-footer h4 {
  color: #fff;
}

.container-footer p {
  font-size: 0.8rem;
}

.title {
  font-family: spaceGrotesk;
}

.container-address {
  line-height: 1rem;
}

.container-media {
  max-width: 400px;
}

#contact-right p:first-child {
  white-space: nowrap;
}

#contact-right a {
  text-decoration: none;
}

#contact-right a:link {
  color: white;
}

.footer-icon-media {
  cursor: pointer;
}

@media (max-width: 1199.9px) {
  .col-center,
  .col-center #contact-left,
  .col-center #contact-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    width: 100%;
  }
}

@media (min-width: 1050px) {
  .footer-icon.icon-mail {
    width: 25px;
  }
}

@media (min-width: 1200px) {
  .container-footer p {
    font-size: 0.85rem;
  }

  .footer-icon {
    width: 22px;
  }

  .footer-icon-media {
    width: 50px;
  }

  .footer-icon.icon-mail {
    width: 30px;
  }
}

@media (min-width: 1300px) {
  .container-footer p {
    font-size: 1rem;
  }
}

@media (min-width: 1800px) {
  .container-footer p {
    font-size: 1.25rem;
  }

  .footer-icon {
    width: 30px;
  }

  .footer-icon-media {
    width: 70px;
  }

  .footer-icon.icon-mail {
    width: 40px;
  }
}

@media (min-width: 2000px) {
  #contact-left {
    padding-left: 150px;
  }
}
