.col-mej .box-left p {
  font-style: italic;
  font-size: 1rem;
}

.col-mej .box-left {
  padding-top: 125px;
  width: 82%;
}

.col-mej .info-container .info-title {
  margin-bottom: 1rem !important;
}

#logo-bj {
  margin-left: -100px;
}

.col-mej .button {
  margin-top: 20px !important;
}

@media (min-width: 520px) {
  .col-mej .box-left {
    padding-top: 120px;
    width: 80%;
  }
}

@media (min-width: 625px) {
  .col-mej .box-left {
    padding-top: 150px;
    width: 85%;
  }
}

@media (min-width: 768px) {
  .col-mej .box-left {
    padding-top: 120px;
  }

  .col-mej .info-container .info-title {
    margin-bottom: 2rem !important;
  }
}

@media (min-width: 790px) {
  .col-mej .info-container .info-title {
    margin-bottom: 3rem !important;
  }
}

@media (min-width: 992px) {
  #logo-bj {
    width: 100% !important;
  }
}

@media (min-width: 950px) {
}

@media (min-width: 1200px) {
  .col-mej .button {
    margin-top: 2rem !important;
  }
}

@media (min-width: 1306px) {
  .col-mej .box-left {
    width: 70%;
  }
}
