.navbar-dark {
  background: none;
  color: #fff;
  font-size: 20px;
  font-family: spaceGrotesk;
  padding-left: 40px !important;
  padding-right: 40px !important;
  transition: background-color 300ms ease-in;
}

.navbar-dark.active {
  background: #112969;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 300ms ease-in;
}

.navbar-dark .navbar-toggler,
.navbar-dark .navbar-toggler:hover {
  outline: none;
}

.nav-link {
  color: #fff !important;
  transition: filter 0.2s;
}

.nav-link.active {
  filter: brightness(85%);
}

@media (min-width: 300px) {
  .navbar-dark {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
}

@media (min-width: 991px) {
  .navbar-dark .nav-item {
    margin-left: 104px;
  }
}

@media (min-width: 1200px) {
  .navbar-dark {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}
