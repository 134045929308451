.card-servico {
  margin-top: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.card-servico a {
  color: #000;
}

.card-servico a:hover {
  color: #000;
  outline: none;
  text-decoration: none;
}

.card-servico .container-card {
  height: auto;
}

.card-servico .container-card p {
  font-size: 1rem;
}

.card-servico .container-card-img {
  height: 120px;
}

@media (min-width: 768px) {
  .card-servico .container-card {
    height: 400px;
  }

  .card-servico .container-card p {
    font-size: 0.8rem;
  }
}

@media (min-width: 857px) {
  .card-servico .container-card {
    height: 350px;
  }
}

@media (min-width: 940px) {
  .card-servico .container-card {
    height: 400px;
  }

  .card-servico .container-card p {
    font-size: 1rem;
  }

  .card-servico .container-card-img {
    height: 140px;
  }
}

@media (min-width: 1200px) {
  .card-servico .container-card {
    height: 480px;
  }

  .card-servico .container-card p {
    font-size: 1.25rem;
  }

  .card-servico .container-card-img {
    height: 180px;
  }
}
