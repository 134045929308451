.container-member {
  padding: 0px !important;
}

.card-img-top img {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.card-icons {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
}

.container.overflow {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
}

.container.overflow img {
  width: 100%;
}

.card-body {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-body p {
  font-size: 12pt;
}
