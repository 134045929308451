.container-box {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 565px;
}

.info-container {
  height: 100%;
  float: left;
}

.box-left p,
.box-right p {
  font-size: 1rem;
}

.box-right h1,
.box-left h1 {
  font-size: 1.8rem !important;
}

.box-left {
  width: 70%;
  display: table;
  margin: 0 auto;
  padding-top: 200px;
  margin-left: 0;
  padding-left: 50px;
}

.box-right {
  width: 70%;
  display: table;
  margin: 0 auto;
  padding-top: 200px;
  margin-right: 0;
  padding-right: 50px;
}

.top-right-icon {
  position: absolute;
  top: 15%;
  right: 0%;
}

.top-left-icon {
  position: absolute;
  top: 10%;
  left: 2%;
}

.left {
  position: absolute;
  max-width: 100%;
  float: left;
  z-index: -1;
}

.right {
  position: absolute;
  max-width: 100%;
  float: right;
  z-index: -1;
  right: 0;
}

@media (min-width: 600px) {
  .container-box {
    width: 90%;
  }

  .top-right-icon {
    top: 10%;
  }

  .top-left-icon {
    top: 4%;
  }
}

@media (min-width: 768px) {
  .box-right {
    width: 75%;
    padding-top: 170px;
  }

  .box-left {
    padding-top: 155px;
  }

  .top-right-icon {
    right: -10%;
    top: 25%;
  }

  .top-left-icon {
    top: 20%;
    left: 0%;
  }

  .info-container .info-title {
    margin-bottom: 1.5rem !important;
  }
}

@media (min-width: 790px) {
  .top-left-icon {
    top: 15%;
  }

  .box-right {
    width: 70%;
    padding-top: 155px;
  }

  .info-container .info-title {
    margin-bottom: 3rem !important;
  }
}

@media (min-width: 890px) {
  .top-right-icon {
    right: -5%;
    top: 15%;
  }

  .top-left-icon {
    top: 10%;
    left: 2%;
  }
}

@media (min-width: 950px) {
  .box-left p,
  .box-right p {
    font-size: 1.25rem;
  }

  .box-right h1,
  .box-left h1 {
    font-size: 2.5rem !important;
  }

  .box-left {
    padding-top: 120px;
  }

  .box-right {
    padding-top: 150px;
  }
}

@media (min-width: 1000px) {
  .top-right-icon {
    top: 10%;
    right: 0%;
  }

  .top-left-icon {
    top: 4%;
  }
}

@media (min-width: 1200px) {
  .box-left {
    padding-left: 100px;
  }

  .box-right {
    padding-right: 100px;
  }

  .container-box {
    max-width: 750px;
  }
}
