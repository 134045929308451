.slick-prev {
  left: -25px !important;
}

.slick-next {
  right: -25px !important;
}

@media (min-width: 480px) {
  .slick-prev {
    left: -40px !important;
  }

  .slick-next {
    right: -40px !important;
  }
}
