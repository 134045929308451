.container-card {
  height: 450px;
}

.container-card-img {
  height: 180px;
  width: 100%;
  margin: 1.4rem 0rem;
  position: relative;
}

.container-card-img img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
