@font-face {
  font-family: spaceGrotesk;
  src: url(../assets/fonts/space-grotesk-regular.otf);
  font-weight: bold;
}

h1,
h2,
h4,
h5 {
  font-family: "Work Sans", sans-serif;
}

h1 {
  font-size: 1.8rem !important;
}

p,
li {
  font-size: 1rem;
  font-family: spaceGrotesk;
}

.container-gradient {
  background: linear-gradient(
    117.6deg,
    #2d95a9 24.27%,
    #1bd36b 101.41%,
    rgba(59, 99, 213, 0.230475) 101.41%,
    rgba(61, 102, 218, 0.19271) 101.42%,
    rgba(17, 40, 105, 0) 101.43%,
    #1bd36b 101.43%
  );
}

.container-content {
  padding-left: 50px !important;
  padding-right: 50px !important;
}

.container-top {
  padding-top: 100px;
  padding-bottom: 50px;
}

.card-shadow {
  padding: 20px;
  box-shadow: 4px 4px 20px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.card-link {
  transition: 300ms ease;
  cursor: pointer;
}

.card-link:hover {
  transform: scale(1.05);
}

.row {
  align-items: center;
}

.button {
  min-width: 150px;
  width: auto;
  background: #112969;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  border: none;
  border-radius: 50px;
  padding: 0.4rem;
  font-weight: 500;
  font-family: spaceGrotesk;
  font-size: 1rem;
  color: #fff;
  transition: filter 0.2s;
}

.button:hover {
  filter: brightness(85%);
}

.button:focus,
.button:active {
  border: none;
  outline: none;
  outline-offset: none;
}

.white-text {
  color: #fff;
}

@media (min-width: 576px) {
  .container-top {
    padding-bottom: 100px;
  }
}

@media (min-width: 768px) {
  p,
  li,
  .button {
    font-size: 1.25rem;
  }

  .button {
    min-width: 200px;
  }

  .card-shadow {
    padding: 30px;
  }

  h1 {
    font-size: 2.5rem !important;
  }
}

@media (min-width: 1200px) {
  .container-content {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .container-top {
    padding-top: 150px;
    padding-bottom: 150px;
  }
}

@media (max-width: 576px) {
  .container-gradient > .container-top {
    height: 106vh;
    display: table-cell;
    vertical-align: middle;
    margin-top: -60px !important;
  }
}
