.open-form p {
  font-size: 0.75rem;
}

.open-form a {
  color: #1bd36b;
  transition: color 200ms ease-in;
}

.open-form a:hover {
  color: #112969;
}

@media (min-width: 768px) {
  .open-form p {
    font-size: 1rem;
  }
}
