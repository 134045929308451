.card-partners {
  position: relative;
  height: auto;
  min-height: 100px;
  max-height: 200px;
}

.card-partners .container-img img {
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  max-width: 80%;
  max-height: 80%;
}
